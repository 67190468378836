import React from "react"
import { PageGrid } from "../styles/Grids"
import PiLab from "../assets/images/pi-lab-pano.jpg"
import GirlsTyping from "../assets/images/girls-typing.jpg"
import styled from "styled-components"
import SEO from "../components/SEO"

export default function CodingPage() {
  const Style = styled.div`
    overflow: hidden;
    a {
      text-decoration: none;
    }
    h2 {
      font-size: var(--heading-1);
      margin-bottom: 1rem;
    }
    p {
    }
    @media (max-width: 800px) {
      margin-bottom: 1rem;
    }
    @media (max-width: 382px) {
      a {
        font-size: var(--heading-3);
      }
    }
  `
  return (
    <PageGrid>
      <SEO title="Myanmar Computer Labs Initiative" />
      <div role="banner">
        <h1 className="center">Myanmar Computer Labs Initiative</h1>
      </div>
      <Style role="main">
        <h2 className="center">
          <a href="mailto:joshuadennis@innovativecommunities.org">
            joshuadennis@innovativecommunities.org
          </a>
        </h2>
        <div className="center" role="button">
          <a
            href="https://www.innovativecommunities.org/communities/myanmar/myanmar-computer-labs/"
            target="_blank"
            rel="noreferrer"
            className="button"
          >
            Donate Now
          </a>
        </div>
        <p className="indented">
          Throughout the country side in Myanmar it is easy to see machines
          taking over the agriculture work thus leaving many people without
          work. Education in computers and technology creates new avenues for
          future jobs. Learning computer skills is essential moving forward as
          the internet of things progresses rapidly. Most of the population in
          the rural villages have never seen a computer. Without computer skills
          the chances of finding gainful employment greatly diminishes, as the
          globalization of the world relies more and more on technology.{" "}
        </p>
        <img src={PiLab} alt="panoramic view of computer lab" />
        <p className="indented">
          Myanmar Computer Labs initiative installs low cost, low power
          consumption computer labs and training in rural schools in Myanmar. A
          computer lab for 20 client computers and an Internet in a Box server
          cost around $5000 CAD. Currently each lab has a trained teacher and
          provision for maintenance. Myanmar Computer Labs doesn’t take
          ethnicity into consideration while building computer labs, but takes
          the need and viability as requisites for building.
        </p>
        <div className="center">
          <img
            src={GirlsTyping}
            style={{ height: 300 }}
            alt="girls typing on computers"
          />
        </div>
        <p className="indented">
          Myanmar Computer Labs initiative has over 30 middle schools and high
          schools awaiting to be outfitted with labs with more requests coming
          in every year. Your donation will go to the purchase of computers,
          battery backups, an Internet in a Box server (loaded with educational
          and training material which is accessible without internet access).
          The speed and number of computer lab deployments will be based on the
          generosity of you the donor.
        </p>
        <div className="center" role="button">
          <a
            href="https://www.innovativecommunities.org/communities/myanmar/myanmar-computer-labs/"
            target="_blank"
            rel="noreferrer"
            className="button"
          >
            Donate Now
          </a>
        </div>
      </Style>
    </PageGrid>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allFile(filter: { ext: { eq: ".jpg" } }) {
      nodes {
        relativePath
        uid
        childImageSharp {
          id
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`
